@import "~animate.css/animate.min.css";
@import "~bootstrap/scss/bootstrap";
@import "./assets/scss/variables.scss";

@font-face {
    font-family: 'roboto light';
    src: url('./assets/fonts/Roboto/Roboto-Light.ttf');
}

@font-face {
    font-family: 'roboto medium';
    src: url('./assets/fonts/Roboto/Roboto-Medium.ttf');
}

@font-face {
    font-family: 'roboto bold';
    src: url('./assets/fonts/Roboto/Roboto-Bold.ttf');
}

@font-face {
    font-family: 'roboto regular';
    src: url('./assets/fonts/Roboto/Roboto-Regular.ttf');
}

.font-roboto-light {
    font-family: $font-roboto-light;
}

.font-roboto-bold {
    font-family: $font-roboto-bold;
}

.font-roboto-medium {
    font-family: $font-roboto-medium;
}
.font-roboto-regular {
    font-family: $font-roboto-regular;
}

.app-body {
    position: relative;
    min-height: 100vh;
    background-color: $lsc-color-light-gray;
    padding-bottom: 124px;
}

.next-page {
    height: 32px;
    width: 32px;
    border-radius: 3px;
    background-color: #99E5EA !important;
    box-shadow: 0 1px 2px 0 rgba(52,55,65,0.25);
}

.previous-page {
    height: 32px;
    width: 32px;
    border-radius: 3px;
    background-color: #F0FBFC !important;
    box-shadow: 0 1px 2px 0 rgba(52,55,65,0.25);
}  

.per-page-item, .per-page-item-link {
    display: inline-block;
    margin: 0.5rem;
    font-size: 0.8rem;
}

.per-page-item-link {
    color: #28A3AF; 
}

.per-page-item-link.active {
    color: $lsc-color-blue; 
}

.pagination {
    display: inline-flex; 
    margin: 2rem;
    text-align: center;

    li {
      height: 2rem;
      width: 2rem;
      border-radius: 3px;
      background-color: #F5F5F5;
      box-shadow: 0 1px 2px 0 rgba(52,55,65,0.25);
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    
      a {
          margin: auto;
      }

      &.active {
        background-color: #1E1045; 
      }

      &.active a {
          color: white; 
      }

    }
}

.divider {
    border-top: 1px solid $lsc-color-gray;
    width: 90%;
}

body {
    font-family: $font-roboto-regular , sans-serif;
}

@media (max-width: 991px){
    .content-wrapper {
        padding-bottom: 48px;    /* Footer height */
    }
}
