.loading-container {
  height: auto;
  width: auto;
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.loading {
  position: absolute;
  background-color: #1e1045;
  opacity: 0.9 !important;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.loading-data {
  background-color: #1e1045;
  opacity: 0.9 !important;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.loading img {
  height: 120px;
  width: 150px;
  margin: auto;
}

@keyframes ldio-qs9l2kov72 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-qs9l2kov72 div {
  animation: ldio-qs9l2kov72 1s linear infinite;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  box-shadow: 0 4px 0 #ffd000;
  transform-origin: 80px 82px;
  box-sizing: content-box;
}

.loadingio-spinner-eclipse-89pd4gzxl1m {
  align-self: center;
  overflow: hidden;
  background: transparent;
}
.ldio-qs9l2kov72 {
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
