body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  width: 11px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #ffffff; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #FFD000;    /* color of the scroll thumb */
  border: 2px solid #ffffff; 
}
